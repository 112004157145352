import { setBusinesses, setSelectedBusiness } from "../slices/business.slice";
import { ENPOINT, STATUS } from "../../constants/server/api";
import { fetchApiData } from "../../utils/apiUtils";

export const getAllBusinesses = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENPOINT.BUSINESS}/list`,
    dispatch,
  });
  if (data?.businesses) {
    dispatch(setBusinesses(data.businesses));
  }
};

export const getBusinessById = (businessId) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENPOINT.BUSINESS}/${businessId}`,
    dispatch,
  });
  if (data) {
    dispatch(setSelectedBusiness(data));
    return data;
  }
};
  
export const getBusinessBySlug = (businessSlug) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENPOINT.BUSINESS}/slug/${businessSlug}`,
    dispatch,
  });
  if (data) {
    dispatch(setSelectedBusiness(data));
    return data;
  }
};

export const createBusiness = (businessData) => async (dispatch) => {
  await fetchApiData({
    method: "post",
    endpoint: `${ENPOINT.BUSINESS}/create`,
    data: businessData,
    dispatch,
  });
  return STATUS.OK;
};

export const deleteBusiness = (businessId) => async (dispatch) => {
  await fetchApiData({
    method: "delete",
    endpoint: `${ENPOINT.BUSINESS}/delete/${businessId}`,
    dispatch,
  });
  return STATUS.OK;
};