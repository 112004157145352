import React, { useCallback, useMemo } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as yup from "yup";
import { ModalAllder } from "layouts/ModalAllder";

export const UnitFormModal = function ({
  modal,
  setModal,
  unitFormData,
  onDelete,
  onSubmit,
  unitTypes = [],
  isEdit = false,
}) {
  const hasUnitTypes = unitTypes.length > 0;

  const schema = useMemo(
    () =>
      yup.object().shape({
        unitName: yup.string().required("กรุณากรอกชื่อหน่วยนับสินค้า"),
        ...(hasUnitTypes && {
          unitType: yup.number().required("กรุณาเลือกหน่วยนับสินค้า"),
        }),
      }),
    [hasUnitTypes]
  );

  const initialValues = useMemo(
    () =>
      isEdit
        ? {
            id: unitFormData?.id || "",
            unitName: unitFormData?.name || "",
            ...(hasUnitTypes && {
              unitType: unitFormData?.count_unit_type_id || unitTypes[0]?.id,
            }),
          }
        : {
            unitName: "",
            ...(hasUnitTypes && {
              unitType: unitTypes[0]?.id,
            }),
          },
    [isEdit, unitFormData, hasUnitTypes, unitTypes]
  );

  const transformValues = useCallback(
    (values) => {
      const submitValues = {
        id: values.id,
        unitName: values.unitName,
      };

      if (hasUnitTypes) {
        submitValues.unitType = values.unitType;
      }

      return submitValues;
    },
    [hasUnitTypes]
  );

  const handleClose = useCallback(
    (resetForm) => {
      if (resetForm) {
        resetForm();
      }
      setModal((prev) => ({ ...prev, open: false }));
    },
    [setModal]
  );

  return (
    <ModalAllder
      modal={modal}
      setModal={setModal}
      htitle={isEdit ? "แก้ไขหน่วยนับสินค้า" : "เพิ่มหน่วยนับสินค้าใหม่"}
      hbtn={true}
      onHide={handleClose}
    >
      <div className="py-4">
        <div className="">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              try {
                setSubmitting(true);
                await onSubmit(transformValues(values));
                resetForm();
                handleClose();
              } catch (error) {
                console.error("Form submission error:", error);
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              touched,
              errors,
              values,
              setFieldValue,
              isSubmitting,
              isValid,
              resetForm,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mb-3 form-st-3 pb-1">
                  <Form.Label className="mb-1">
                    ชื่อหน่วยนับสินค้า <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="unitName"
                    className="form-control"
                    placeholder="ชื่อหน่วยนับสินค้า"
                    value={values.unitName}
                    onChange={handleChange}
                    isInvalid={!!errors.unitName}
                  />
                  {errors.unitName && touched.unitName && (
                    <Form.Control.Feedback type="invalid">
                      {errors.unitName}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                {hasUnitTypes && (
                  <div>
                    <Form.Label className="mb-2 tc-10">
                      หน่วยนับสินค้า <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="mb-3">
                      {unitTypes.map((unitType) => (
                        <Form.Check
                          key={unitType.id}
                          inline
                          label={unitType.name}
                          name="unitType"
                          type="radio"
                          id={`unitType-${unitType.id}`}
                          className="form-check-st-2 tc-7"
                          checked={values.unitType === unitType.id}
                          onChange={() => {
                            setFieldValue("unitType", unitType.id);
                          }}
                        />
                      ))}
                    </div>
                    {errors.unitType && touched.unitType && (
                      <div className="text-danger small">{errors.unitType}</div>
                    )}
                  </div>
                )}

                <div
                  className={`${
                    isEdit ? "d-flex align-items-center" : "text-center"
                  } pt-3 pb-3 btn-group-control`}
                >
                  {isEdit && (
                    <Button
                      type="button"
                      className="btn-st-5 me-auto"
                      style={{ width: "80px" }}
                      onClick={() => {
                        resetForm();
                        onDelete();
                      }}
                      disabled={isSubmitting}
                    >
                      ลบ
                    </Button>
                  )}
                  <Button
                    type="button"
                    className="btn-st-6 me-3"
                    style={{ width: "100px" }}
                    onClick={() => {
                      resetForm();
                      handleClose(resetForm);
                    }}
                    disabled={isSubmitting}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="submit"
                    className="btn-st-3"
                    style={{ width: "100px" }}
                    disabled={isSubmitting || !isValid}
                  >
                    {isSubmitting ? "กำลังบันทึก..." : "ยืนยัน"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </ModalAllder>
  );
};
