export const createTableColumns = (columns, actions) => {
    return columns.map(column => {
      if (column.type === 'status') {
        return {
          ...column,
          renderCell: ({ row }) => (
            <div className={`datalist-status status${row[column.field] ? '1' : '0'}`} />
          ),
        };
      }
      if (column.type === 'actions') {
        return {
          ...column,
          renderCell: ({ row }) => actions(row),
        };
      }
      return column;
    });
  };