import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  businesses: null,
  user: null,
  selectedBusiness: null,
  businessForm: {
    basic: {
      taxNumber: "",
      type: "JURISTIC",
      business_type: "",      
      company_name: "",
      first_name: "",
      last_name: "",
    },
    details: {
      restaurantTypeId: "",
      shopProductCategoryTypeId: "",
      slug: "",
    },
  },
};

const slice = createSlice({
  name: "userBusiness",
  initialState,
  reducers: {
    resetState: () => initialState,
    setBusinesses: (state, action) => {
      state.businesses = action.payload;
    },
    setSelectedBusiness: (state, action) => {
      state.selectedBusiness = action.payload;
    },

    setBusinessFormBasic: (state, action) => {
      state.businessForm.basic = {
        ...state.businessForm.basic,
        ...action.payload,
      };
    },
    setBusinessFormDetails: (state, action) => {
      state.businessForm.details = {
        ...state.businessForm.details,
        ...action.payload,
      };
    },
    resetBusinessForm: (state) => {
      state.businessForm = initialState.businessForm;
    },
  },
});

export const {
  resetState,
  setBusinesses,
  setSelectedBusiness,
  setBusinessFormBasic,
  setBusinessFormDetails,
  resetBusinessForm,
} = slice.actions;

export default slice.reducer;
