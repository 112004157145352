import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { debounce } from "lodash";
import { useParams } from "react-router-dom";
import { GeneralNumberSettingsOption } from "./GeneralNumberSettingsOption";
import { settingGeneralHandler } from "../../../../redux/handlers";

const DIGIT_OPTIONS = Array.from({ length: 8 }, (_, i) => ({
  value: i + 2,
  label: i + 2,
}));

const createValidationSchema = (prefixes) => {
  const schemaFields = prefixes.reduce((acc, prefix) => {
    return {
      ...acc,
      [`${prefix}_runnumber_format`]: yup
        .string()
        .required("กรุณาเลือกรูปแบบการรันเอกสาร"),
      [`${prefix}_initial_characters`]: yup
        .string()
        .required("กรุณาระบุตัวอักษรเริ่มต้น"),
      [`${prefix}_special_characters`]: yup.string(),
      [`${prefix}_year_format`]: yup.string().required("กรุณาเลือกรูปแบบปี"),
      [`${prefix}_month_format`]: yup
        .string()
        .required("กรุณาเลือกรูปแบบเดือน"),
      [`${prefix}_date_format`]: yup.string().required("กรุณาเลือกรูปแบบวัน"),
      [`${prefix}_digit_format`]: yup.number().required("กรุณาเลือกจำนวนหลัก"),
    };
  }, {});
  return yup.object().shape(schemaFields);
};

const useFormOptions = (masterData) => {
  return useMemo(
    () => ({
      documentRunFormatOption:
        masterData?.counterSettingMaster?.map((counter) => ({
          value: counter.id,
          label: counter.name,
        })) || [],
      dateOption:
        masterData?.codeSettingDays?.map((day) => ({
          value: day.id,
          label: day.name,
        })) || [],
      monthoption:
        masterData?.codeSettingMonths?.map((month) => ({
          value: month.id,
          label: month.name,
        })) || [],
      yearOption:
        masterData?.codeSettingYears?.map((year) => ({
          value: year.id,
          label: year.name,
        })) || [],
      digitOption: DIGIT_OPTIONS,
    }),
    [masterData]
  );
};

const createDefaultValues = (prefix) => ({
  [`${prefix}_runnumber_format`]: "",
  [`${prefix}_initial_characters`]: prefix.toUpperCase(),
  [`${prefix}_special_characters`]: "",
  [`${prefix}_year_format`]: "",
  [`${prefix}_month_format`]: "",
  [`${prefix}_date_format`]: "",
  [`${prefix}_digit_format`]: DIGIT_OPTIONS[0].value,
});

const createSettingValues = (setting, defaultValues) => {
  const prefix = setting.codeMaster.model.substring(0, 3);

  return {
    ...defaultValues,
    [`${prefix}_runnumber_format`]:
      setting.counter_setting_master_id ||
      defaultValues[`${prefix}_runnumber_format`],
    [`${prefix}_initial_characters`]:
      setting.prefix || defaultValues[`${prefix}_initial_characters`],
    [`${prefix}_special_characters`]:
      setting.symbol_text || defaultValues[`${prefix}_special_characters`],
    [`${prefix}_year_format`]:
      setting.code_setting_year_id || defaultValues[`${prefix}_year_format`],
    [`${prefix}_month_format`]:
      setting.code_setting_month_id || defaultValues[`${prefix}_month_format`],
    [`${prefix}_date_format`]:
      setting.code_setting_day_id || defaultValues[`${prefix}_date_format`],
    [`${prefix}_digit_format`]: Number(
      setting.digit || defaultValues[`${prefix}_digit_format`]
    ),
  };
};

export function GeneralNumberSettingsTab() {
  const dispatch = useDispatch();
  const { businessSlug } = useParams();
  const [formDisabled, setFormDisabled] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const [previewCodes, setPreviewCodes] = useState({});

  const masterData = useSelector((state) => state.master);
  const codeSettingData = useSelector((state) => state.codeSetting.data);
  const formOptions = useFormOptions(masterData);

  const validationSchema = useMemo(() => {
    const prefixes =
      codeSettingData?.map((setting) =>
        setting.codeMaster.model.substring(0, 3)
      ) || [];
    return createValidationSchema(prefixes);
  }, [codeSettingData]);

  const generatePreviews = async (values) => {
    if (!values) return;

    try {
      const previews = {};
      for (const setting of codeSettingData || []) {
        const prefix = setting.codeMaster.model.substring(0, 3);
        const params = {
          prefix: values[`${prefix}_initial_characters`],
          symbol_text: values[`${prefix}_special_characters`],
          digit: values[`${prefix}_digit_format`],
          code_setting_year_id: values[`${prefix}_year_format`],
          code_setting_month_id: values[`${prefix}_month_format`],
          code_setting_day_id: values[`${prefix}_date_format`],
          counter_setting_master_id: values[`${prefix}_runnumber_format`],
        };

        try {
          const response = await dispatch(
            settingGeneralHandler.getGenerateExample(params)
          );
          previews[prefix] = response?.code || "";
        } catch (error) {
          console.error(`Error generating preview for ${prefix}:`, error);
          previews[prefix] = "";
        }
      }
      setPreviewCodes(previews);
    } catch (error) {
      console.error("Error in generatePreviews:", error);
    }
  };

  const debouncedGeneratePreviews = useCallback(
    debounce((values) => {
      if (!formDisabled) {
        generatePreviews(values);
      }
    }, 100),
    [formDisabled, codeSettingData]
  );

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const updatePromises = codeSettingData.map((setting) => {
        const prefix = setting.codeMaster.model.substring(0, 3);
        const payload = {
          code_master_id: setting.codeMaster.id,
          code_setting_type_id: setting.code_setting_type_id,
          code_setting_year_id: values[`${prefix}_year_format`],
          code_setting_month_id: values[`${prefix}_month_format`],
          code_setting_day_id: values[`${prefix}_date_format`],
          counter_setting_master_id: values[`${prefix}_runnumber_format`],
          prefix: values[`${prefix}_initial_characters`],
          symbol_text: values[`${prefix}_special_characters`] || "",
          digit: values[`${prefix}_digit_format`],
        };
        return dispatch(
          settingGeneralHandler.updateCodeSettings(businessSlug, payload)
        );
      });

      await Promise.all(updatePromises);
      setFormDisabled(true);
    } catch (error) {
      console.error("Error updating code settings:", error);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (codeSettingData?.length) {
      const defaultValues = codeSettingData.reduce((acc, setting) => {
        const prefix = setting.codeMaster.model.substring(0, 3);
        return { ...acc, ...createDefaultValues(prefix) };
      }, {});

      const formValues = codeSettingData.reduce(
        (acc, setting) => createSettingValues(setting, acc),
        defaultValues
      );

      setInitialValues(formValues);
      generatePreviews(formValues);
    }
  }, [codeSettingData]);

  const FormActions = ({ isSubmitting, handleCancel }) => (
    <div className="text-end pb-10 pe-3">
      {formDisabled ? (
        <Button
          type="button"
          className="btn-st-3 mx-3"
          onClick={() => setFormDisabled(false)}
          disabled={isSubmitting}
        >
          แก้ไข
        </Button>
      ) : (
        <div className="text-end pt-3 btn-group-control">
          <Button
            type="button"
            className="btn-st-5 me-3"
            onClick={handleCancel}
            disabled={isSubmitting}
          >
            ยกเลิก
          </Button>
          <Button type="submit" className="btn-st-3" disabled={isSubmitting}>
            บันทึก
          </Button>
        </div>
      )}
    </div>
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => {
        const handleChange = (e) => {
          formikProps.handleChange(e);
          setTimeout(() => {
            debouncedGeneratePreviews({
              ...formikProps.values,
              [e.target.name]: e.target.value,
            });
          }, 0);
        };

        const handleCancel = () => {
          setFormDisabled(true);
          formikProps.resetForm();
          setPreviewCodes({});
          if (codeSettingData) {
            generatePreviews(initialValues);
          }
        };

        return (
          <Form noValidate onSubmit={formikProps.handleSubmit}>
            <fieldset disabled={formikProps.isSubmitting}>
              <div className="card-body px-xl-4">
                {codeSettingData?.map((setting) => (
                  <React.Fragment key={setting.id}>
                    <GeneralNumberSettingsOption
                      title={setting.codeMaster.name}
                      model={setting.codeMaster.model}
                      previewCode={
                        previewCodes[
                          setting.codeMaster.model.substring(0, 3)
                        ] || ""
                      }
                      currentSetting={setting}
                      options={formOptions}
                      formdisabled={formDisabled}
                      {...formikProps}
                      handleChange={handleChange}
                    />
                    <hr className="my-4" />
                  </React.Fragment>
                ))}
              </div>
            </fieldset>
            <FormActions
              isSubmitting={formikProps.isSubmitting}
              handleCancel={handleCancel}
            />
          </Form>
        );
      }}
    </Formik>
  );
}
