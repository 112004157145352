import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Outlet,
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useAppDispatch } from "../../redux/store/configureStore";
import { userBusinessGroupHandler } from "../../redux/handlers";
import { STATUS } from "../../constants/server/api";

export function RegisterBusinessConfirmEmail() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [isVerified, setIsVerified] = useState(false);

  const getVerifyCodeFromUrl = () => {
    const searchParams = new URLSearchParams(location.search);
    const verifyCode = searchParams.get("verifyCode");
    return verifyCode;
  };

  useEffect(() => {
    checkVerificationStatus();
  }, []);

  const checkVerificationStatus = async () => {
    try {
      const verifyCode = getVerifyCodeFromUrl();
      if (!verifyCode) return;

      const response = await dispatch(
        userBusinessGroupHandler.checkVerificationStatus(verifyCode)
      );

      if (response) {
        setIsVerified(response.isVerified);
      }
    } catch (error) {
      console.error("Failed to check verification status:", error);
    }
  };

  const handleVerifyEmail = async () => {
    try {
      const verifyCode = getVerifyCodeFromUrl();
      if (!verifyCode) {
        console.error("Verify code not found in URL");
        return;
      }

      const response = await dispatch(
        userBusinessGroupHandler.verifyEmail(verifyCode)
      );

      if (response === STATUS.OK) {
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      }
    } catch (error) {
      console.error("Failed to verify email:", error);
    }
  };

  return (
    <div className="bg-[#FFF]">
      <Container className="">
        <div className="m-0 w-100 py-sm-2 bg-white h-100vh d-flex flex-column">
          <div className="mb-auto">
            <Link to="/login" className="btn btn-back-st-1 mb-auto">
              กลับหน้า Login
            </Link>
          </div>
          <div className="mb-auto">
            <div className="max-w-[400px] mx-auto">
              <div className="text-left text-[#18191C] text-[24px] font-semibold mb-7">
                ขั้นตอนสุดท้าย
              </div>
              <div className="text-[#767F8C] text-left  mb-7">
                ยืนยันที่อยู่อีเมลเพื่อให้บัญชีของคุณเสร็จสมบูรณ์ <br />
                โดยมีขั้นตอนง่ายๆโดยคลิกที่ปุ่มด้านล่าง
              </div>
              <div className="text-center">
                {!isVerified && (
                  <Button
                    className=" mx-auto w-full h-[48px] border-0 bg-[#2DC76D] text-white"
                    onClick={() => {
                      handleVerifyEmail();
                    }}
                  >
                    ยืนยันเดี๋ยวนี้
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="text-center fs-20 pb-4 mt-5 tc-3 d-flex align-items-end justify-content-center">
            <span className="me-3 ">Power By</span>
            <div className="pb-05">
              <Image src="/images/logo_allder.png" width={98} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
