import React, { useEffect, useState, useCallback } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import InfoIcon from "@mui/icons-material/Info";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import BusinessImageUpload from "./BusinessImageUpload";
import {
  settingBusinessHandler,
  masterHandler,
} from "../../../../redux/handlers";
import { useParams } from "react-router-dom";

const validationSchema = yup.object().shape({
  settingTaxNumber: yup.string().required("กรุณากรอกเลขประจำตัวผู้เสียภาษี"),
  settingSlug: yup
    .string()
    .required("กรุณากรอกลิงค์กิจการ")
    .test(
      "length-validation",
      "URL ธุรกิจต้องมีความยาวระหว่าง 6-25 ตัวอักษร",
      (value) => {
        if (!value) return true;
        //
        if (
          /[\u0E00-\u0E7F\u0600-\u06FF\u3040-\u30FF\u4E00-\u9FFF]/.test(value)
        ) {
          return true;
        }

        const slugValue = value
          .trim()
          .toLowerCase()
          .replace(/\s+/g, "-")
          .replace(/[^a-z0-9-]/g, "")
          .replace(/-+/g, "-")
          .replace(/^-+/g, "")
          .replace(/-+$/g, "");

        if (slugValue.length < 6) {
          return new yup.ValidationError(
            "URL ธุรกิจหลังจากแปลงแล้วต้องมีความยาวอย่างน้อย 6 ตัวอักษร",
            value,
            "settingSlug"
          );
        }
        if (slugValue.length > 25) {
          return new yup.ValidationError(
            "URL ธุรกิจหลังจากแปลงแล้วต้องมีความยาวไม่เกิน 25 ตัวอักษร",
            value,
            "settingSlug"
          );
        }
        return true;
      }
    ),
  settingEntityTypeId: yup.string().required("กรุณาเลือกประเภทกิจการ"),
  settingPrefixNameId: yup.string().required("กรุณาเลือกคำนำหน้า"),
  settingFirstName: yup.string().when("settingEntityTypeId", {
    is: "2",
    then: () => yup.string().required("กรุณากรอกชื่อจริง"),
    otherwise: () => yup.string().nullable(),
  }),
  settingCompanyName: yup.string().when("settingEntityTypeId", {
    is: "1",
    then: () => yup.string().required("กรุณากรอกชื่อบริษัท"),
    otherwise: () => yup.string().nullable(),
  }),
  settingLastName: yup.string().when("settingEntityTypeId", {
    is: "2",
    then: () => yup.string().required("กรุณากรอกนามสกุล"),
    otherwise: () => yup.string().nullable(),
  }),
  settingRestaurantTypeId: yup.string().required("กรุณาเลือกประเภทร้านอาหาร"),
  settingShopProductCategoryTypeId: yup
    .string()
    .required("กรุณาเลือกประเภทสินค้า"),
});

const defaultInitialValues = {
  settingImagePath: "",
  settingSlug: "",
  settingTaxNumber: "",
  settingEntityTypeId: "",
  settingPrefixNameId: "",
  settingCompanyName: "",
  settingFirstName: "",
  settingLastName: "",
  settingCompanyLastName: "",
  settingAddress: "",
  settingSubDistrictId: "",
  settingDistrict: "",
  settingProvince: "",
  settingPostalCode: "",
  settingRestaurantTypeId: "",
  settingShopProductCategoryTypeId: "",
};

const BusinessSettingsTabInfo = ({ formdisabled, setFormdisabled }) => {
  const dispatch = useDispatch();
  const { businessSlug } = useParams();
  const selectedBusiness = useSelector(
    (state) => state.business.selectedBusiness?.business
  );
  const {
    entityTypes,
    prefixNames,
    shopProductCategories,
    restaurantTypes,
    provinces,
    districts,
    subDistricts,
  } = useSelector((state) => state.master);

  const [initialValues, setInitialValues] = useState(defaultInitialValues);
  const [entityIdCode, setEntityConde] = useState(null);

  useEffect(() => {
    dispatch(
      masterHandler.getPrefixNames(
        selectedBusiness?.entity_type_id == 1 ? "JURISTIC" : "NATURAL"
      )
    );
  }, [selectedBusiness]);

  const createOptions = useCallback(
    (data = [], labelKey = "name", valueKey = "id") => {
      return data?.map((item) => ({
        value: item[valueKey].toString(),
        label: item[labelKey],
        ...item,
      }));
    },
    []
  );

  const businessTypeOptions = createOptions(entityTypes);
  const prefixTypeOptions = createOptions(prefixNames);
  const productTypeOptions = createOptions(shopProductCategories);
  const restaurantTypeOptions = createOptions(restaurantTypes);

  const validatePreviewSlug = (value) => {
    const pattern = /^[a-z0-9]+(?:-[a-z0-9]+)*-?$/;
    return pattern.test(value);
  };

  const convertToSlug = (value) => {
    return value
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^a-z0-9-]/g, "")
      .replace(/-+/g, "-")
      .replace(/^-+/g, "")
      .replace(/-+$/g, "");
  };

  useEffect(() => {
    if (selectedBusiness) {
      const {
        image_path,
        tax_number,
        company_name,
        first_name,
        last_name,
        address: businessAddress,
        slug,
        entityType,
        restaurantType,
        shopProductCategory,
        sub_district_id,
        prefix_name_id,
        subDistrict,
      } = selectedBusiness;

      const district = subDistrict?.district;
      const province = district?.province;

      const companyLastName =
        entityType?.id == 1
          ? prefixNames?.find((e) => e.id == prefix_name_id)?.last_name || ""
          : "";

      setInitialValues({
        ...defaultInitialValues,
        settingImagePath: image_path || "",
        settingSlug: slug || "",
        settingTaxNumber: tax_number || "",
        settingEntityTypeId: entityType?.id?.toString() || "",
        settingPrefixNameId: prefix_name_id?.toString() || "",
        settingCompanyName: company_name || "",
        settingFirstName: first_name || "",
        settingLastName: last_name || "",
        settingCompanyLastName: companyLastName || "",
        settingAddress: businessAddress || "",
        settingSubDistrictId: sub_district_id?.toString() || "",
        settingDistrict: district?.id?.toString() || "",
        settingProvince: province?.id?.toString() || "",
        settingPostalCode: subDistrict?.zip_code || "",
        settingRestaurantTypeId: restaurantType?.id?.toString() || "",
        settingShopProductCategoryTypeId:
          shopProductCategory?.id?.toString() || "",
      });

      const loadAddressData = async () => {
        try {
          await Promise.all([
            dispatch(masterHandler.getProvinces()),
            province?.id && dispatch(masterHandler.getDistricts(province.id)),
            district?.id &&
              dispatch(masterHandler.getSubDistricts(district.id)),
          ]);
        } catch (error) {
          console.error("Error loading address data:", error);
        }
      };
      loadAddressData();
    }
  }, [selectedBusiness, prefixNames, dispatch]);

  useEffect(() => {
    dispatch(masterHandler.getPrefixNames(entityIdCode));
  }, [entityIdCode]);

  const handleSubmit = async (values) => {
    try {
      const formattedValues = {
        ...values,
        settingSlug: convertToSlug(values.settingSlug),
      };
      const result = await dispatch(
        settingBusinessHandler.updateBusinessSlugInfo(
          businessSlug,
          formattedValues
        )
      );
      if (result === "OK") {
        setFormdisabled(true);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleEntityTypeChange = (e, setFieldValue) => {
    setFieldValue("settingEntityTypeId", e.value);
    setFieldValue("settingPrefixNameId", "");
    setFieldValue("settingLastName", "");
    setFieldValue("settingCompanyLastName", "");
    dispatch(masterHandler.getPrefixNames(e.code));
  };

  const handlePrefixChange = (e, values, setFieldValue) => {
    setFieldValue("settingPrefixNameId", e.value);
    if (values.settingEntityTypeId === "1") {
      const companyLastName =
        prefixNames?.find((p) => p.id == e.id)?.last_name || "";
      setFieldValue("settingCompanyLastName", companyLastName || null);
    } else {
      setFieldValue("settingLastName", e.suffix || null);
    }
  };

  const handleProvinceChange = async (e, setFieldValue) => {
    try {
      setFieldValue("settingProvince", e.value);
      setFieldValue("settingDistrict", "");
      setFieldValue("settingSubDistrictId", "");
      setFieldValue("settingPostalCode", "");
      await dispatch(masterHandler.getDistricts(e.value));
    } catch (error) {
      console.error("Error loading districts:", error);
    }
  };

  const handleDistrictChange = async (e, setFieldValue) => {
    try {
      setFieldValue("settingDistrict", e.value);
      setFieldValue("settingSubDistrictId", "");
      setFieldValue("settingPostalCode", "");
      await dispatch(masterHandler.getSubDistricts(e.value));
    } catch (error) {
      console.error("Error loading sub-districts:", error);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        touched,
        errors,
        values,
        setFieldValue,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <fieldset disabled={formdisabled}>
            <div className="card-body px-xl-4">
              <div>
                <BusinessImageUpload
                  setFieldValue={setFieldValue}
                  values={values}
                  onUploadSuccess={(fileInfo) => {
                    setFieldValue("settingImagePath", fileInfo.base64);
                  }}
                  onUploadError={(error) => {}}
                />

                <div className="pt-3">
                  <div className="row gx-xl-4">
                    <div className="col-xl-4">
                      <Form.Group className="mb-2 form-st-3 pb-1">
                        <Form.Label className="mb-1">
                          เลขที่ประจำตัวผู้เสียภาษี
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="settingTaxNumber"
                          className="form-control"
                          placeholder="เลขที่ประจำตัวผู้เสียภาษี"
                          value={values.settingTaxNumber}
                          onChange={handleChange}
                          isInvalid={!!errors.settingTaxNumber}
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          maxLength={13}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.settingTaxNumber}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    <div className="col-xl-4">
                      <Form.Group className="mb-2 form-st-3 pb-1">
                        <Form.Label className="mb-1">
                          ชื่อ URL ธุรกิจของคุณ
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="settingSlug"
                          className="form-control"
                          placeholder="กรอก URL ของคุณ"
                          value={values.settingSlug}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (
                              !/[\u0E00-\u0E7F\u0600-\u06FF\u3040-\u30FF\u4E00-\u9FFF]/.test(
                                value
                              )
                            ) {
                              setFieldValue("settingSlug", value);
                            }
                          }}
                          isInvalid={!!errors.settingSlug}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.settingSlug}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <div className="form-text text-blue mt-[-4px] mb-2">
                        <p>
                          ตัวอย่าง URL ของคุณ :{" "}
                          <span className="text-blue-700 font-bold text-xl">
                            {validatePreviewSlug(
                              convertToSlug(values.settingSlug)
                            )
                              ? convertToSlug(values.settingSlug)
                              : ""}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="col-xl-4 align-self-center mb-8">
                      <div className="bg-[#D7D9FF] py-1 px-2 text-[#061126] text-[12px] flex items-center rounded font-semibold">
                        <InfoIcon className="text-[#2D3CED] text-[12px] mr-2" />
                        การเปลี่ยน มีผลทำให้ URL การเข้าเว็บไซต์เปลี่ยนไป
                      </div>
                    </div>

                    <div className="col-xl-4">
                      <Form.Group className="mb-2 form-st-3 pb-1">
                        <Form.Label className="mb-1">ประเภทกิจการ</Form.Label>
                        <Select
                          name="settingEntityTypeId"
                          onChange={(e) =>
                            handleEntityTypeChange(e, setFieldValue)
                          }
                          className={
                            !!errors.settingEntityTypeId
                              ? "w-100 select-st-1 isInvalid"
                              : "w-100 select-st-1"
                          }
                          options={businessTypeOptions}
                          placeholder="เลือกประเภทกิจการ"
                          value={businessTypeOptions?.filter(
                            (item) => item.value === values.settingEntityTypeId
                          )}
                        />
                        {errors.settingEntityTypeId && (
                          <div className="invalid-feedback d-block">
                            {errors.settingEntityTypeId}
                          </div>
                        )}
                      </Form.Group>
                    </div>

                    <div className="col-xl-8">
                      <div className="row gx-xl-4">
                        {values.settingEntityTypeId !== "11" && (
                          <div className="col-xl-4">
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">คำนำหน้า</Form.Label>
                              <Select
                                name="settingPrefixNameId"
                                onChange={(e) =>
                                  handlePrefixChange(e, values, setFieldValue)
                                }
                                className={
                                  !!errors.settingPrefixNameId
                                    ? "w-100 select-st-1 isInvalid"
                                    : "w-100 select-st-1"
                                }
                                options={prefixTypeOptions}
                                placeholder="เลือกคำนำหน้า"
                                value={prefixTypeOptions?.filter(
                                  (item) =>
                                    item.value === values.settingPrefixNameId
                                )}
                              />
                              {errors.settingPrefixNameId && (
                                <div className="invalid-feedback d-block">
                                  {errors.settingPrefixNameId}
                                </div>
                              )}
                            </Form.Group>
                          </div>
                        )}

                        <div
                          className={`col-xl-${
                            values.settingEntityTypeId === "11" ? "8" : "4"
                          }`}
                        >
                          {values.settingEntityTypeId === "1" ? (
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">
                                ชื่อกิจการ *
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="settingCompanyName"
                                className="form-control"
                                placeholder="ชื่อกิจการ"
                                value={values.settingCompanyName}
                                onChange={handleChange}
                                isInvalid={!!errors.settingCompanyName}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.settingCompanyName}
                              </Form.Control.Feedback>
                            </Form.Group>
                          ) : (
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">
                                ชื่อจริง *
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="settingFirstName"
                                className="form-control"
                                placeholder="ชื่อจริง"
                                value={values.settingFirstName}
                                onChange={handleChange}
                                isInvalid={!!errors.settingFirstName}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.settingFirstName}
                              </Form.Control.Feedback>
                            </Form.Group>
                          )}
                        </div>

                        {values.settingEntityTypeId === "1" ? (
                          <div className="col-xl-4">
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">คำลงท้าย</Form.Label>
                              <Form.Control
                                type="text"
                                name="settingCompanyLastName"
                                className="form-control"
                                placeholder="คำลงท้าย"
                                value={values.settingCompanyLastName}
                                onChange={handleChange}
                                disabled={true}
                                isInvalid={!!errors.settingCompanyLastName}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.settingCompanyLastName}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        ) : (
                          <div className="col-xl-4">
                            <Form.Group className="mb-2 form-st-3 pb-1">
                              <Form.Label className="mb-1">นามสกุล</Form.Label>
                              <Form.Control
                                type="text"
                                name="settingLastName"
                                className="form-control"
                                placeholder="นามสกุล"
                                value={values.settingLastName}
                                onChange={handleChange}
                                isInvalid={!!errors.settingLastName}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.settingLastName}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-xl-12">
                      <Form.Group className="mb-2 form-st-3 pb-1">
                        <Form.Label className="mb-1">ที่อยู่</Form.Label>
                        <Form.Control
                          type="text"
                          name="settingAddress"
                          className="form-control"
                          placeholder="กรอกที่อยู่"
                          value={values.settingAddress}
                          onChange={handleChange}
                          isInvalid={!!errors.settingAddress}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.settingAddress}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    <div className="col-xl-6">
                      <Form.Group className="mb-2 form-st-3 pb-1">
                        <Form.Label className="mb-1">จังหวัด</Form.Label>
                        <Select
                          name="settingProvince"
                          onChange={(e) =>
                            handleProvinceChange(e, setFieldValue)
                          }
                          className={
                            !!errors.settingProvince
                              ? "w-100 select-st-1 isInvalid"
                              : "w-100 select-st-1"
                          }
                          options={
                            provinces?.map((p) => ({
                              value: p.id.toString(),
                              label: p.name_th,
                            })) || []
                          }
                          placeholder="เลือกจังหวัด"
                          value={
                            provinces
                              ?.filter(
                                (p) =>
                                  p.id.toString() === values.settingProvince
                              )
                              .map((p) => ({
                                value: p.id.toString(),
                                label: p.name_th,
                              })) || null
                          }
                        />
                        {errors.settingProvince && (
                          <div className="invalid-feedback d-block">
                            {errors.settingProvince}
                          </div>
                        )}
                      </Form.Group>
                    </div>

                    <div className="col-xl-6">
                      <Form.Group className="mb-2 form-st-3 pb-1">
                        <Form.Label className="mb-1">เขต/อำเภอ</Form.Label>
                        <Select
                          name="settingDistrict"
                          onChange={(e) =>
                            handleDistrictChange(e, setFieldValue)
                          }
                          className={
                            !!errors.settingDistrict
                              ? "w-100 select-st-1 isInvalid"
                              : "w-100 select-st-1"
                          }
                          options={
                            districts?.map((d) => ({
                              value: d.id.toString(),
                              label: d.name_th,
                            })) || []
                          }
                          placeholder="เลือกเขต/อำเภอ"
                          value={
                            districts
                              ?.filter(
                                (d) =>
                                  d.id.toString() === values.settingDistrict
                              )
                              .map((d) => ({
                                value: d.id.toString(),
                                label: d.name_th,
                              })) || null
                          }
                          isDisabled={!values.settingProvince}
                        />
                        {errors.settingDistrict && (
                          <div className="invalid-feedback d-block">
                            {errors.settingDistrict}
                          </div>
                        )}
                      </Form.Group>
                    </div>

                    <div className="col-xl-6">
                      <Form.Group className="mb-2 form-st-3 pb-1">
                        <Form.Label className="mb-1">แขวง/ตำบล</Form.Label>
                        <Select
                          name="settingSubDistrictId"
                          onChange={(e) => {
                            setFieldValue("settingSubDistrictId", e.value);
                            const subDistrict = subDistricts?.find(
                              (s) => s.id.toString() === e.value
                            );
                            if (subDistrict) {
                              setFieldValue(
                                "settingPostalCode",
                                subDistrict.zip_code
                              );
                            }
                          }}
                          className={
                            !!errors.settingSubDistrictId
                              ? "w-100 select-st-1 isInvalid"
                              : "w-100 select-st-1"
                          }
                          options={
                            subDistricts?.map((s) => ({
                              value: s.id.toString(),
                              label: s.name_th,
                              zip_code: s.zip_code,
                            })) || []
                          }
                          placeholder="เลือกแขวง/ตำบล"
                          value={
                            subDistricts
                              ?.filter(
                                (s) =>
                                  s.id.toString() ===
                                  values.settingSubDistrictId
                              )
                              .map((s) => ({
                                value: s.id.toString(),
                                label: s.name_th,
                              })) || null
                          }
                          isDisabled={!values.settingDistrict}
                        />
                        {errors.settingSubDistrictId && (
                          <div className="invalid-feedback d-block">
                            {errors.settingSubDistrictId}
                          </div>
                        )}
                      </Form.Group>
                    </div>

                    <div className="col-xl-6">
                      <Form.Group className="mb-2 form-st-3 pb-1">
                        <Form.Label className="mb-1">รหัสไปรษณีย์</Form.Label>
                        <Form.Control
                          type="text"
                          name="settingPostalCode"
                          className="form-control"
                          placeholder="รหัสไปรษณีย์"
                          value={values.settingPostalCode}
                          disabled
                          isInvalid={!!errors.settingPostalCode}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.settingPostalCode}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    <div className="col-xl-6">
                      <Form.Group className="mb-2 form-st-3 pb-1">
                        <Form.Label className="mb-1">
                          ประเภทร้านอาหาร
                        </Form.Label>
                        <Select
                          name="settingRestaurantTypeId"
                          onChange={(e) => {
                            setFieldValue("settingRestaurantTypeId", e.value);
                          }}
                          className={
                            !!errors.settingRestaurantTypeId
                              ? "w-100 select-st-1 isInvalid"
                              : "w-100 select-st-1"
                          }
                          options={restaurantTypeOptions}
                          placeholder="เลือกประเภทร้านอาหาร"
                          value={restaurantTypeOptions?.filter(
                            (item) =>
                              item.value === values.settingRestaurantTypeId
                          )}
                        />
                        {errors.settingRestaurantTypeId && (
                          <div className="invalid-feedback d-block">
                            {errors.settingRestaurantTypeId}
                          </div>
                        )}
                      </Form.Group>
                    </div>

                    <div className="col-xl-6">
                      <Form.Group className="mb-2 form-st-3 pb-1">
                        <Form.Label className="mb-1">
                          เลือกประเภทสินค้า
                        </Form.Label>
                        <Select
                          name="settingShopProductCategoryTypeId"
                          onChange={(e) => {
                            setFieldValue(
                              "settingShopProductCategoryTypeId",
                              e.value
                            );
                          }}
                          className={
                            !!errors.settingShopProductCategoryTypeId
                              ? "w-100 select-st-1 isInvalid"
                              : "w-100 select-st-1"
                          }
                          options={productTypeOptions}
                          placeholder="เลือกประเภทสินค้า"
                          value={productTypeOptions?.filter(
                            (item) =>
                              item.value ===
                              values.settingShopProductCategoryTypeId
                          )}
                        />
                        {errors.settingShopProductCategoryTypeId && (
                          <div className="invalid-feedback d-block">
                            {errors.settingShopProductCategoryTypeId}
                          </div>
                        )}
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <div className="text-end pb-4 pe-3">
            {formdisabled ? (
              <Button
                type="button"
                className="btn-st-3 mx-3"
                onClick={() => setFormdisabled(false)}
              >
                แก้ไข
              </Button>
            ) : (
              <div className="text-end pt-3 btn-group-control">
                <Button
                  type="button"
                  className="btn-st-5 me-3"
                  onClick={() => setFormdisabled(true)}
                >
                  ยกเลิก
                </Button>
                <Button type="submit" className="btn-st-3">
                  บันทึก
                </Button>
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default BusinessSettingsTabInfo;
