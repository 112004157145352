import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import BusinessSettingsTabInfo from "./components/BusinessSettingsTabInfo";
import BusinessSettingsTabContact from "./components/BusinessSettingsTabContact";
import BusinessSettingsTabSale from "./components/BusinessSettingsTabSale";
import { businessHandler, masterHandler } from "../../../redux/handlers";

const TABS = [
  {
    id: 1,
    icon: "i-document",
    label: "ข้อมูลธุรกิจ",
    component: BusinessSettingsTabInfo,
  },
  {
    id: 2,
    icon: "i-location",
    label: "ข้อมูลติดต่อ",
    component: BusinessSettingsTabContact,
  },
  {
    id: 3,
    icon: "i-setting",
    label: "ตั้งค่าการขาย",
    component: BusinessSettingsTabSale,
  },
];

const Breadcrumb = () => (
  <nav aria-label="breadcrumb" className="mt-2">
    <ol className="breadcrumb fs-16 ps-0">
      <li className="breadcrumb-item">
        <a href="#" className="tc-7 text-decoration-none">
          ตั้งค่าธุรกิจ
        </a>
      </li>
      <li className="breadcrumb-item active tc-1" aria-current="page">
        ข้อมูลธุรกิจ
      </li>
    </ol>
  </nav>
);

const TabNavigation = ({ activeTab, isEditMode, onTabChange }) => (
  <nav>
    <div className="nav nav-tabs nav-tabs-st-2" role="tablist">
      {TABS.map(({ id, icon, label }) => (
        <button
          key={id}
          className={`nav-link ${activeTab === id ? "active" : ""}`}
          type="button"
          onClick={() => isEditMode && onTabChange(id)}
          disabled={!isEditMode}
        >
          <span className={`${icon} me-2`}></span> {label}
        </button>
      ))}
    </div>
  </nav>
);

export function BusinessSettings() {
  const dispatch = useDispatch();
  const { businessSlug } = useParams();
  console.log(businessSlug);
  const [activeTab, setActiveTab] = useState(1);
  const [isEditMode, setIsEditMode] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);

  const initializeMasterData = async (entityTypeCode) => {
    try {
      await Promise.all([
        dispatch(masterHandler.getEntityTypes()),
        dispatch(masterHandler.getRestaurantTypes()),
        dispatch(masterHandler.getPrefixNames(entityTypeCode)),
        dispatch(masterHandler.getShopProductCategories()),
        dispatch(masterHandler.getProvinces()),
      ]);
    } catch (error) {
      console.error("Error initializing master data:", error);
    }
  };

  const initializeBusinessData = async () => {
    try {
      const businessData = await dispatch(businessHandler.getBusinessBySlug(businessSlug));
      await initializeMasterData(businessData?.business?.entityType?.code);
      setIsEditMode(true);
      setFormDisabled(true);
    } catch (error) {
      console.error("Error initializing business data:", error);
    }
  };

  useEffect(() => {
    if (businessSlug) {
      initializeBusinessData();
    }
  }, [businessSlug]);

  const handleTabChange = (selectedTab) => {
    setActiveTab(selectedTab);
    if (isEditMode) {
      dispatch(businessHandler.getBusinessBySlug(businessSlug));
    }
  };

  const CurrentTabComponent = TABS.find(
    (tab) => tab.id === activeTab
  )?.component;

  if (!CurrentTabComponent) return null;

  return (
    <div>
      <Breadcrumb />
      <div className="fs-24 tc-7 mb-4 lh-1">ข้อมูลธุรกิจ</div>
      <div className="card card-st-1">
        <div className="card-header">
          <TabNavigation
            activeTab={activeTab}
            isEditMode={isEditMode}
            onTabChange={handleTabChange}
          />
        </div>
        <CurrentTabComponent
          formDisabled={formDisabled}
          setFormDisabled={setFormDisabled}
        />
      </div>
    </div>
  );
}
