import React from "react";
import { useParams, Outlet } from "react-router-dom";

import "../styles/sidemenu.css";
import "../styles/style.css";

import { Header } from "./header";
import { Navcustom } from "./nav";

export function Layout({ selectbranchID, setSelectBranchID }) {
  const { businessSlug } = useParams();
  return (
    <div className="page">
      <Header selectbranchID={selectbranchID} businessSlug={businessSlug} />
      <div className="main-content app-content">
        <Navcustom
          setSelectBranchID={setSelectBranchID}
          selectbranchID={selectbranchID}
          businessSlug={businessSlug}
        />
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
