import * as masterAction from "../slices/master.slice";
import { ENPOINT } from "../../constants/server/api";
import { fetchApiData } from "../../utils/apiUtils";

export const getPrefixNames = (entityTypeCode) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENPOINT.MASTER.PREFIX_NAME}/list`,
    params: { entity_type_code: entityTypeCode },
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setPrefixNames(data));
  }
};

export const getShopProductCategories = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENPOINT.MASTER.SHOP_PRODUCT}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setShopProductCategories(data));
  }
};

export const getRestaurantTypes = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENPOINT.MASTER.RESTAURANT}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setRestaurantTypes(data));
  }
};

export const getEntityTypes = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENPOINT.MASTER.ENTITY}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setEntityTypes(data));
  }
};

export const getProvinces = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENPOINT.MASTER.PROVINCE}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setProvinces(data));
  }
};

export const getDistricts = (provinceId) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENPOINT.MASTER.DISTRICT}/list`,
    params: { province_id: provinceId },
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setDistricts(data));
  }
};

export const getSubDistricts = (districtId) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENPOINT.MASTER.SUB_DISTRICT}/list`,
    params: { district_id: districtId },
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setSubDistricts(data));
  }
};

export const getCurrencies = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENPOINT.MASTER.CURRENCY}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setCurrencies(data));
  }
};

export const getVatCalculations = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENPOINT.MASTER.VAT}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setVatCalculations(data));
  }
};

export const getCodeSettingDayList = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENPOINT.MASTER.CODE_SETTING_DAY}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setCodeSettingDays(data));
  }
};

export const getCodeSettingMonthList = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENPOINT.MASTER.CODE_SETTING_MONTH}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setCodeSettingMonths(data));
  }
};

export const getCodeSettingYearList = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENPOINT.MASTER.CODE_SETTING_YEAR}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setCodeSettingYears(data));
  }
};

export const getCounterSettingMaster = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENPOINT.MASTER.COUNTER_SETTING}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setCounterSettingMaster(data));
  }
};

export const getCountUnitTypes = () => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENPOINT.MASTER.COUNT_UNIT_TYPE}/list`,
    dispatch,
    useAuth: false,
  });
  if (data) {
    dispatch(masterAction.setCountUnitTypes(data));
  }
};
