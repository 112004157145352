import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";

const BusinessImageUpload = ({
  setFieldValue,
  values,
  onUploadSuccess,
  onUploadError,
  onRemove,
  maxSizeByte = 3 * 1024 * 1024,
}) => {
  const [imgData, setImgData] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (values.settingImagePath && !originalImage) {
      setOriginalImage(values.settingImagePath);
    }
  }, [values.settingImagePath]);

  const handlePreviewImage = (e) => {
    const file = e.target.files[0];
    setError(null);

    if (file) {
      if (!file.type.match("image.*")) {
        const errorMsg = "กรุณาอัพโหลดไฟล์รูปภาพเท่านั้น";
        setError(errorMsg);
        onUploadError?.(errorMsg);
        return;
      }

      if (file.size > maxSizeByte) {
        const errorMsg = `ขนาดไฟล์ต้องไม่เกิน ${maxSizeByte / (1024 * 1024)}MB`;
        setError(errorMsg);
        onUploadError?.(errorMsg);
        return;
      }

      const reader = new FileReader();

      reader.addEventListener("load", () => {
        const newImage = reader.result;
        setImgData(newImage);
        setFieldValue("settingImagePath", newImage);

        onUploadSuccess?.({
          file,
          base64: newImage,
          name: file.name,
          size: file.size,
          type: file.type,
        });
      });

      reader.addEventListener("error", () => {
        const errorMsg = "เกิดข้อผิดพลาดในการอ่านไฟล์";
        setError(errorMsg);
        onUploadError?.(errorMsg);
      });

      reader.readAsDataURL(file);
    }
  };

  const handleRemove = () => {
    setImgData(null);
    setFieldValue("settingImagePath", originalImage);
    setError(null);
    onRemove?.();

    const fileInput = document.getElementById("fileUpload");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  return (
    <div className="d-flex align-items-center mb-4 mt-3">
      <div className="bx-upload">
        {values.settingImagePath ? (
          <img src={imgData || values.settingImagePath} alt="Business" />
        ) : (
          <div className="preimg"></div>
        )}
      </div>
      <input
        type="file"
        className="fileUpload hide-input"
        id="fileUpload"
        accept="image/*"
        onChange={handlePreviewImage}
      />

      <div className="flex-fill ms-3">
        <div className="mb-3">
          <Button
            type="button"
            className="btn btn-st-4 btn-upload me-3"
            onClick={() => document.getElementById("fileUpload").click()}
          >
            อัปโหลดรูปภาพ
          </Button>
          {imgData && (
            <Button
              type="button"
              className="btn btn-st-2 btn-remove-image"
              onClick={handleRemove}
            >
              ลบ
            </Button>
          )}
        </div>
        {error ? (
          <div className="text-danger fs-12">{error}</div>
        ) : (
          <div className="tc-7 fs-12">
            อัพโหลดไฟล์ JPG, GIF or PNG. ขนาดไม่เกิน{" "}
            {maxSizeByte / (1024 * 1024)}MB
          </div>
        )}
      </div>
    </div>
  );
};

export default BusinessImageUpload;
