import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Select from "react-select";

import { Formik } from "formik";
import * as yup from "yup";

import {
  useAppDispatch,
  useAppSelector,
} from "../../redux/store/configureStore";

import { userBusinessGroupHandler, masterHandler } from "../../redux/handlers";
import { STATUS } from "../../constants/server/api";

const schema = yup.object().shape({
  prefix: yup.string().required(),
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  phonenumber: yup.string().required().min(10).max(10),
  email: yup.string().required().email("Invalid email"),
  password: yup.string().required(),
  confirmpassword: yup
    .string()
    .required()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export function RegisterBusinessEmail() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const prefixNameData = useAppSelector((state) => state.master.prefixNames);
  const [inputtype, setInputtype] = useState("password");
  const [inputtype2, setInputtype2] = useState("password");

  useEffect(() => {
    dispatch(masterHandler.getPrefixNames("NATURAL"));
  }, [dispatch]);

  const handleRegister = async (values) => {
    try {
      const registerData = {
        prefix_name_id: values.prefix,
        firstName: values.firstname,
        lastName: values.lastname,
        email: values.email,
        password: values.password,
        phoneNumber: values.phonenumber,
      };

      const response = await dispatch(
        userBusinessGroupHandler.userBusinessRegister(registerData)
      );

      if (response === STATUS.OK) {
        navigate("/register/verify", {
          state: { email: values.email },
        });
      }
    } catch (error) {
      console.error("Registration failed:", error);
    }
  };

  const optionsPrefix =
    prefixNameData?.map((prefix) => ({
      value: prefix.id,
      label: prefix.name,
    })) || [];

  return (
    <div className="bg-[#FFF]">
      <Container className="">
        <div className="m-0 w-100 py-sm-2 bg-white h-100vh d-flex flex-column">
          <div className="mb-auto">
            <Link to="/login" className="btn btn-back-st-1 mb-auto">
              กลับหน้า Login
            </Link>
          </div>
          <div className="mb-auto">
            <Formik
              initialValues={{
                prefix: "",
                firstname: "",
                lastname: "",
                phonenumber: "",
                email: "",
              }}
              validationSchema={schema}
              onSubmit={async (values) => {
                handleRegister(values);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                touched,
                isValid,
                errors,
                values,
                setFieldValue,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Card className="card-register-st-1 !h-auto mb-4">
                    <Card.Body>
                      <div className="text-start pt-4 fs-20 fw-5 mb-4 pb-2">
                        ข้อมูลส่วนตัว
                      </div>

                      <Form.Group className="mb-3 form-st-2 pb-1">
                        <Form.Label className="mb-1">คำนำหน้า</Form.Label>
                        <Select
                          as="select"
                          name="prefix"
                          onChange={(e) => {
                            setFieldValue("prefix", e.value);
                          }}
                          className={`w-100 select-st-1 ${
                            !!errors.prefix ? "error" : ""
                          }  `}
                          options={optionsPrefix}
                          placeholder="เลือกคำนำหน้า"
                        />
                      </Form.Group>

                      <Form.Group className="mb-3 form-st-2 pb-1">
                        <Form.Label className="mb-1">
                          ชื่อ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="firstname"
                          placeholder="กรอกชื่อ"
                          value={values.firstname}
                          onChange={handleChange}
                          isValid={touched.firstname && !errors.firstname}
                          isInvalid={!!errors.firstname}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3 form-st-2 pb-1">
                        <Form.Label className="mb-1">
                          นามสกุล <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="lastname"
                          placeholder="กรอกนามสกุล"
                          value={values.lastname}
                          onChange={handleChange}
                          isValid={touched.lastname && !errors.lastname}
                          isInvalid={!!errors.lastname}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3 form-st-2 pb-1">
                        <Form.Label className="mb-1">
                          เบอร์โทร <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="phonenumber"
                          placeholder="กรอกเบอร์โทร"
                          value={values.phonenumber}
                          onChange={handleChange}
                          isValid={touched.phonenumber && !errors.phonenumber}
                          isInvalid={!!errors.phonenumber}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          maxLength={10}
                        />
                      </Form.Group>

                      <div className="text-start pt-4 fs-20 fw-5 mb-4 pb-2">
                        ข้อมูลการใช้งาน
                      </div>

                      <Form.Group className="mb-3 form-st-2 pb-1">
                        <Form.Label className="mb-1">
                          อีเมล <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="กรอกอีเมล"
                          value={values.email}
                          onChange={handleChange}
                          isValid={touched.email && !errors.email}
                          isInvalid={!!errors.email}
                        />
                      </Form.Group>

                      <div className="position-relative">
                        <Form.Control
                          type={inputtype}
                          name="password"
                          placeholder="รหัสผ่าน"
                          onChange={handleChange}
                          isValid={touched.password && !errors.password}
                          isInvalid={!!errors.password}
                          className="form-st-1 mb-4"
                        />
                        <Button
                          type="button"
                          className="btn btn-change-type-password"
                          onClick={() =>
                            inputtype == "password"
                              ? setInputtype("text")
                              : setInputtype("password")
                          }
                        ></Button>
                      </div>

                      <div className="position-relative">
                        <Form.Control
                          type={inputtype2}
                          name="confirmpassword"
                          placeholder="รหัสผ่าน"
                          onChange={handleChange}
                          isValid={
                            touched.confirmpassword && !errors.confirmpassword
                          }
                          isInvalid={!!errors.confirmpassword}
                          className="form-st-1 mb-4"
                        />
                        <Button
                          type="button"
                          className="btn btn-change-type-password"
                          onClick={() =>
                            inputtype2 == "password"
                              ? setInputtype2("text")
                              : setInputtype2("password")
                          }
                        ></Button>
                      </div>
                    </Card.Body>
                  </Card>
                  <div className="text-center py-2">
                    <Link to="/login" className="btn-st-2 btn mx-3">
                      ยกเลิก
                    </Link>
                    <Button
                      type="submit"
                      className="btn-st-2 !bg-[#2DC76D] !border-[#FFFFFF] !text-[#FFFFFF] mx-3"
                    >
                      ถัดไป
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="text-center fs-20 pb-4 mt-5 tc-3 d-flex align-items-end justify-content-center">
            <span className="me-3 ">Power By</span>
            <div className="pb-05">
              <Image src="/images/logo_allder.png" width={98} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
