import React, { useEffect, useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userBusinessGroupHandler } from "../redux/handlers";

export const BusinessGuard = ({ children }) => {
  const [notFound, setNotFound] = useState(false);
  const { businessSlug } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const validateBusiness = async () => {
      try {
        const response = await dispatch(
          userBusinessGroupHandler.checkBusinessGuard(businessSlug)
        );

        if (!response) {
          setNotFound(true);
        }
      } catch (error) {}
    };

    if (businessSlug) {
      validateBusiness();
    }
  }, [businessSlug, dispatch]);

  if (notFound) {
    return <Navigate to="*" replace />;
  }

  return children;
};
