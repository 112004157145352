import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useDispatch, useSelector } from "react-redux";
import { businessHandler } from "../../redux/handlers";
import { env } from "../../env";

export function BusinessInfo() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const selectedBusiness = useSelector(
    (state) => state.business?.selectedBusiness
  );

  useEffect(() => {
    if (id) {
      dispatch(businessHandler.getBusinessById(id));
    }
  }, [dispatch, id]);

  return (
    <div className="bg-[#FFF]">
      <Container className="">
        <div className="m-0 w-100 py-sm-2 bg-white h-100vh d-flex flex-column">
          <div className="mb-auto">
            <Link to="/business" className="btn btn-back-st-1 mb-auto">
              ย้อนกลับ
            </Link>
          </div>
          {selectedBusiness?.business ? (
            <div className="mb-auto ">
              <img
                src={
                  selectedBusiness.business?.image_path ||
                  "/images/image-placeholder.jpeg"
                }
                className="mx-auto rounded-md w-full h-full aspect-square object-cover max-w-48 mb-4"
                alt="Business"
              />
              <div className="font-bold text-[20px] text-center mb-3">
                <span className="text-[#061126]">ชื่อกิจการ : </span>
                <span className="text-[#2DC76D]">
                  {selectedBusiness.business?.entityType?.code == "NATURAL"
                    ? `${selectedBusiness.business.first_name} ${selectedBusiness.business.last_name}`
                    : selectedBusiness.business.compony_name}
                </span>
              </div>
              <div className="text-center text-[#4C4E64]/[0.87] mb-4">
                ท่านสามารถจัดการร้านค้าของท่านได้ตามลิงค์ดังนี้
              </div>

              <div className="flex items-center  mb-4 max-w-[650px] mx-auto ">
                <div>Backoffice</div>
                <div className="rounded-lg px-3 py-2   overflow-hidden  bg-[#EAF9F0] text-[#2DC76D] lg:mx-4 mx-2 glow">
                  <div className="flex">
                    <InsertLinkIcon className="mr-3" />{" "}
                    {env.REACT_APP_URL}/{selectedBusiness.business.slug}
                  </div>
                </div>
                <CopyToClipboard
                  text={`${process.env.REACT_APP_URL}/${selectedBusiness.business.slug}`}
                >
                  <Button className="border-0 text-[#2DC76D] py-[1px] w-[60px] bg-[#FFF]">
                    <div>
                      <ContentCopyIcon className="text-[14px]" />
                    </div>
                    <div className="text-[10px] text-nowrap">คัดลอก</div>
                  </Button>
                </CopyToClipboard>
              </div>

              <div className="text-center mb-5">
                <Link
                  to={`${env.REACT_APP_URL}/${selectedBusiness.business.slug}/setting/business`}
                  className="bg-[#2DC76D] text-[#FFF] btn mx-3 hover:bg-[#2DC76D]"
                >
                  จัดการข้อมูลธุรกิจ
                </Link>
              </div>

              <div className="text-[#4C4E64]/[0.87] text-center text-lg mb-3">
                เลือกสาขา
              </div>
              <div className="flex justify-center flex-wrap mb-5">
                {/* {business &&
                  business.branch &&
                  business.branch.map((item, index) => (
                    <div
                      key={`inline-${index}`}
                      className={` ${
                        item.branchID === branch.branchID ? "bg-[#2DC76D]" : ""
                      } mb-3 flex items-center rounded shadow w-[243px] p-2 mx-3 h-[73px] cursor-pointer`}
                      onClick={() => {
                        setBranch(item);
                      }}
                    >
                      <img
                        src={item.branchImage}
                        className="w-[50px] h-[50px] rounded-full object-cover mr-2"
                        alt="branch"
                      />
                      <div
                        className={` ${
                          item.branchID === branch.branchID ? "text-[#FFF]" : ""
                        } `}
                      >
                        <div>{item.branchName}</div>
                        <div
                          className={` ${
                            item.branchID === branch.branchID
                              ? "text-[#FFF]"
                              : "text-[#3C4043]"
                          } text-sm`}
                        >
                          ({item.branchCode})
                        </div>
                      </div>
                    </div>
                  ))} */}
              </div>
              <div className="max-w-[650px] mx-auto">
                <div className="flex items-center  mb-4">
                  <div>POS</div>
                  <div className="rounded-lg px-3 py-2   overflow-hidden  bg-[#EAF9F0] text-[#2DC76D] mx-4 glow">
                    <div className="flex">
                      <InsertLinkIcon className="mr-3" />{" "}
                      {process.env.REACT_APP_URL}/
                      {selectedBusiness.business.slug}/login
                    </div>
                  </div>
                  <CopyToClipboard
                    text={selectedBusiness.branch?.pos_url || ""}
                  >
                    <Button className="border-0 text-[#2DC76D] py-[1px] bg-[#FFF]">
                      <div>
                        <ContentCopyIcon className="text-[14px]" />
                      </div>
                      <div className="text-[10px] text-nowrap">คัดลอก</div>
                    </Button>
                  </CopyToClipboard>
                </div>

                <div className="flex items-center  mb-4">
                  <div>STAFF</div>
                  <div
                    className={`rounded-lg px-3 py-2   overflow-hidden  bg-[#EAF9F0] ${
                      selectedBusiness.branch?.staff_url
                        ? "text-[#2DC76D]"
                        : "text-[#000000]"
                    }  mx-4 glow`}
                  >
                    <div className="flex">
                      <InsertLinkIcon className="mr-3" />
                      <div className="text-nowrap">
                        {selectedBusiness.branch?.staff_url
                          ? selectedBusiness.branch?.staff_url
                          : "กรุณาเปิดใช้งานระบบ Staff ก่อน"}
                      </div>
                    </div>
                  </div>
                  {selectedBusiness.branch?.staff_url ? (
                    <CopyToClipboard text={selectedBusiness.branch?.staff_url}>
                      <Button className="border-0 text-[#2DC76D] py-[1px] bg-[#FFF]">
                        <div>
                          <ContentCopyIcon className="text-[14px]" />
                        </div>
                        <div className="text-[10px] text-nowrap">คัดลอก</div>
                      </Button>
                    </CopyToClipboard>
                  ) : (
                    ""
                  )}
                </div>
                {/* {branch ? (
                  <>
                    <div className="flex items-center  mb-4">
                      <div>POS</div>
                      <div className="rounded-lg px-3 py-2   overflow-hidden  bg-[#EAF9F0] text-[#2DC76D] mx-4 glow">
                        <div className="flex">
                          <InsertLinkIcon className="mr-3" />{" "}
                          {env.REACT_APP_URL}/
                          {selectedBusiness.business.slug}/login
                        </div>
                      </div>
                      <CopyToClipboard text={branch.pos_url}>
                        <Button className="border-0 text-[#2DC76D] py-[1px] bg-[#FFF]">
                          <div>
                            <ContentCopyIcon className="text-[14px]" />
                          </div>
                          <div className="text-[10px] text-nowrap">คัดลอก</div>
                        </Button>
                      </CopyToClipboard>
                    </div>

                    <div className="flex items-center  mb-4">
                      <div>STAFF</div>
                      <div
                        className={`rounded-lg px-3 py-2   overflow-hidden  bg-[#EAF9F0] ${
                          branch.staff_url ? "text-[#2DC76D]" : "text-[#000000]"
                        }  mx-4 glow`}
                      >
                        <div className="flex">
                          <InsertLinkIcon className="mr-3" />
                          <div className="text-nowrap">
                            {branch.staff_url
                              ? branch.staff_url
                              : "กรุณาเปิดใช้งานระบบ Staff ก่อน"}
                          </div>
                        </div>
                      </div>
                      {branch.staff_url ? (
                        <CopyToClipboard text={branch.staff_url}>
                          <Button className="border-0 text-[#2DC76D] py-[1px] bg-[#FFF]">
                            <div>
                              <ContentCopyIcon className="text-[14px]" />
                            </div>
                            <div className="text-[10px] text-nowrap">
                              คัดลอก
                            </div>
                          </Button>
                        </CopyToClipboard>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                ) : (
                  ""
                )} */}
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="text-center fs-20 pb-4 mt-5 tc-3 d-flex align-items-end justify-content-center">
            <span className="me-3 ">Power By</span>
            <div className="pb-05">
              <Image src="/images/logo_allder.png" width={98} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
