import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import { BusinessGuard } from "./components/BusinessGuard";

import { LoginBusiness } from "./pages/user-business-group/LoginBusiness";
import { RegisterBusinessGmail } from "./pages/user-business-group/RegisterBusinessGmail";
import { RegisterBusinessEmail } from "./pages/user-business-group/RegisterBusinessEmail";
import { RegisterBusinessType } from "./pages/user-business-group/RegisterBusinessType";
import { RegisterBusinessVerify } from "./pages/user-business-group/RegisterBusinessVerify";
import { RegisterBusinessConfirmEmail } from "./pages/user-business-group/RegisterBusinessConfirmEmail";

import { Business } from "./pages/business/Business";
import { BusinessFormBasic } from "./pages/business/BusinessFormBasic";
import { BusinessFormGeneral } from "./pages/business/BusinessFormGeneral";
import { BusinessInfo } from "./pages/business/BusinesInfo";

import { Layout } from "./layouts/layout";
import { BusinessSettings } from "./pages/settings/business/BusinessSettings";
import { ProductCountUnit } from "./pages/settings/products/ProductCountUnit";
import { ProductSaleUnit } from "./pages/settings/products/ProductSaleUnit";
import { GeneralNumberSettings } from "./pages/settings/general/GeneralNumberSettings";

import { NotFound } from "./pages/NotFound";
import { PublicRoute } from "./components/PublicRoute";

function App() {
  const [selectbranchID, setSelectBranchID] = useState(1);

  const checkToken = () => {
    const token = localStorage.getItem("accessToken");
    return !!token;
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          checkToken() ? (
            <Navigate to="/business" replace />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />

      <Route
        path="/login"
        element={
          <PublicRoute>
            <LoginBusiness />
          </PublicRoute>
        }
      />

      <Route path="/register" element={<RegisterBusinessType />} />
      <Route path="/register/gmail" element={<RegisterBusinessGmail />} />
      <Route path="/register/email" element={<RegisterBusinessEmail />} />
      <Route path="/register/verify" element={<RegisterBusinessVerify />} />
      <Route
        path="/register/confirm/email"
        element={<RegisterBusinessConfirmEmail />}
      />

      <Route
        path="/business"
        element={
          <ProtectedRoute>
            <Business />
          </ProtectedRoute>
        }
      />
      <Route
        path="/business/create"
        element={
          <ProtectedRoute>
            <BusinessFormBasic />
          </ProtectedRoute>
        }
      />
      <Route
        path="/business/create/info"
        element={
          <ProtectedRoute>
            <BusinessFormGeneral />
          </ProtectedRoute>
        }
      />
      <Route
        path="/business/info/:id"
        element={
          <ProtectedRoute>
            <BusinessInfo />
          </ProtectedRoute>
        }
      />

      <Route
        path="/:businessSlug"
        element={
          <ProtectedRoute>
            <BusinessGuard>
              <Layout
                setSelectBranchID={setSelectBranchID}
                selectbranchID={selectbranchID}
              />
            </BusinessGuard>
          </ProtectedRoute>
        }
      >
        <Route
          path="setting/business"
          element={
            <ProtectedRoute>
              <BusinessSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path="setting/product/unit"
          element={
            <ProtectedRoute>
              <ProductCountUnit />
            </ProtectedRoute>
          }
        />
        <Route
          path="setting/product/saleunit"
          element={
            <ProtectedRoute>
              <ProductSaleUnit />
            </ProtectedRoute>
          }
        />
        <Route
          path="setting/document"
          element={
            <ProtectedRoute>
              <GeneralNumberSettings />
            </ProtectedRoute>
          }
        />
      </Route>      
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
