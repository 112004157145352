import React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

const DIGIT_OPTIONS = Array.from({ length: 8 }, (_, i) => ({
  value: i + 2,
  label: i + 2,
}));

const FormTextField = ({
  label,
  name,
  value,
  onChange,
  disabled,
  error,
  helperText,
  options = [],
  type = "text",
  fullWidth = true,
}) => (
  <TextField
    select={type === "select"}
    label={label}
    fullWidth={fullWidth}
    variant="outlined"
    name={name}
    value={value || ""}
    onChange={onChange}
    disabled={disabled}
    error={!!error}
    helperText={helperText}
  >
    {type === "select" &&
      options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
  </TextField>
);

const PreviewHeader = ({ title, previewCode }) => (
  <div className="flex items-center">
    <div className="flex items-center mr-auto font-medium">
      <div>{title}</div>
      <div className="h-[18px] w-[1px] mx-3 border-[1px] border-[#E1E1E1]"></div>
      <div>{previewCode}</div>
    </div>
  </div>
);

export function GeneralNumberSettingsOption({
  title,
  model,
  handleChange,
  values,
  setFieldValue,
  formdisabled,
  options,
  previewCode,
  errors,
  touched,
}) {
  const prefix = model?.substring(0, 3);

  if (!prefix || !options) return null;

  const getErrorMessage = (fieldName) =>
    touched[fieldName] && errors[fieldName];

  const handleFieldChange = (name, value) => {
    Promise.resolve().then(() => {
      setFieldValue(name, value);
      handleChange({ target: { name, value } });
    });
  };

  const formFields = [
    {
      label: "ตัวอักษรเริ่มต้น",
      name: `${prefix}_initial_characters`,
    },
    {
      label: "ตัวอักษรพิเศษ",
      name: `${prefix}_special_characters`,
    },
    {
      label: "ปี",
      name: `${prefix}_year_format`,
      type: "select",
      options: options.yearOption,
    },
    {
      label: "เดือน",
      name: `${prefix}_month_format`,
      type: "select",
      options: options.monthoption,
    },
    {
      label: "วัน",
      name: `${prefix}_date_format`,
      type: "select",
      options: options.dateOption,
    },
    {
      label: "จำนวนหลัก (Digit)",
      name: `${prefix}_digit_format`,
      type: "select",
      options: DIGIT_OPTIONS,
      transform: Number,
    },
  ];

  return (
    <div>
      <div className="flex justify-between w-full mb-4">
        <PreviewHeader title={title} previewCode={previewCode} />
        <div className="w-[250px] mt-2">
          <FormTextField
            type="select"
            label="รูปแบบการรันเอกสาร"
            name={`${prefix}_runnumber_format`}
            value={values[`${prefix}_runnumber_format`]}
            onChange={(e) =>
              handleFieldChange(`${prefix}_runnumber_format`, e.target.value)
            }
            disabled={formdisabled}
            error={getErrorMessage(`${prefix}_runnumber_format`)}
            helperText={getErrorMessage(`${prefix}_runnumber_format`)}
            options={options.documentRunFormatOption}
          />
        </div>
      </div>

      <div className="grid grid-cols-12 lg:gap-4 gap-2">
        {formFields.map(
          ({ label, name, type, options: fieldOptions, transform }) => (
            <div key={name} className="col-span-2">
              <FormTextField
                label={label}
                name={name}
                value={values[name]}
                onChange={(e) => {
                  const value = transform
                    ? transform(e.target.value)
                    : e.target.value;
                  handleFieldChange(name, value);
                }}
                disabled={formdisabled}
                error={getErrorMessage(name)}
                helperText={getErrorMessage(name)}
                type={type}
                options={fieldOptions}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default GeneralNumberSettingsOption;
