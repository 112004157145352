import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useGoogleLogin } from "@react-oauth/google";

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Formik } from "formik";
import * as yup from "yup";
import { userBusinessGroupHandler } from "../../redux/handlers";
import { STATUS } from "../../constants/server/api";
import { ERROR_MESSAGES } from "../../constants/server/error";

export function LoginBusiness() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    username: yup.string().required(),
    password: yup.string().required(),
  });

  const [inputtype, setInputtype] = useState("password");
  const [inputtype2, setInputtype2] = useState("password");
  const [googlelogin, setGooglelogin] = useState(false);
  const [rememberedEmail, setRememberedEmail] = useState(() => {
    return localStorage.getItem("rememberedEmail") || "";
  });

  const handleLogin = async (credentialResponse) => {
    try {
      const values = JSON.parse(credentialResponse);
      const response = await dispatch(
        userBusinessGroupHandler.userBusinessLogin(
          values.username,
          values.password
        )
      );

      if (response == "NOT_HAS_VERIFY_EMAIL") {
        navigate("/register/verify");
      }

      if (response == STATUS.OK) {
        values.remember
          ? localStorage.setItem("rememberedEmail", values.username)
          : localStorage.removeItem("rememberedEmail");
        navigate("/business");
      }
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  const [userinfo, setUserinfo] = useState(false);
  const [useremail, setUseremail] = useState("");

  const handleGoogleLogin = async (googleRes) => {
    try {
      const response = await dispatch(
        userBusinessGroupHandler.userBusinessGoogleLogin(
          googleRes.sub,
          googleRes.email
        )
      );
      if (response == "NOT_HAS_VERIFY_EMAIL") {
        navigate("/register/verify");
      }
      if (response == "USER_NOT_FOUND") {
        navigate(`/register/gmail`, {
          state: { google_id: googleRes.sub, email: googleRes.email },
        });
      }
      if (response === STATUS.OK) {
        navigate("/business");
      }
    } catch (error) {
      console.error("Google login failed:", error);
    }
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setGooglelogin(true);
      fetch(
        "https://www.googleapis.com/oauth2/v3/userinfo?access_token=" +
          tokenResponse.access_token
      )
        .then((response) => response.json())
        .then(async (result) => {
          setUserinfo(result);
          setUseremail(result.email);
          await handleGoogleLogin(result);
        })
        .catch((error) => {});
    },
  });

  return (
    <div className="">
      <div className="m-0 w-100 p-0 bg-white ">
        <Row className="g-0 h-100vh">
          <Col xl={8} lg={6} className="d-none d-lg-block">
            <Image src="/images/cover_login.png" className="img-fit" />
          </Col>
          <Col xl={4} lg={6} className="d-flex flex-column py-4">
            <div className="mb-auto"></div>
            <Card className="card-login-st-1 mb-auto">
              <Card.Body>
                <div className="text-center">
                  <Image src="/logo.png" width={150} className="mb-4 mx-auto" />
                </div>
                <div className="text-center fs-18 fw-5 mb-4">เข้าสู่ระบบ</div>
                <Formik
                  initialValues={{
                    username: rememberedEmail || "",
                    password: "",
                    remember: !!rememberedEmail,
                  }}
                  validationSchema={schema}
                  onSubmit={async (values) => {
                    handleLogin(JSON.stringify(values, null, 2));
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    touched,
                    isValid,
                    errors,
                    values,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Form.Control
                        type="text"
                        name="username"
                        placeholder="อีเมลหรือเบอร์โทรศัพท์"
                        onChange={handleChange}
                        isValid={touched.username && !errors.username}
                        isInvalid={!!errors.username}
                        className="form-st-1 mb-4"
                        value={values.username || useremail}
                        onKeyPress={(e) => {
                          setUseremail(e.value);
                        }}
                      />
                      <div className="position-relative">
                        <Form.Control
                          type={inputtype}
                          name="password"
                          placeholder="รหัสผ่าน"
                          onChange={handleChange}
                          isValid={touched.password && !errors.password}
                          isInvalid={!!errors.password}
                          className="form-st-1 mb-4"
                        />
                        <Button
                          type="button"
                          className="btn btn-change-type-password"
                          onClick={() =>
                            inputtype === "password"
                              ? setInputtype("text")
                              : setInputtype("password")
                          }
                        ></Button>
                      </div>

                      {/* {googlelogin ? (
                        <div className="position-relative">
                          <Form.Control
                            type={inputtype2}
                            name="confirmpassword"
                            placeholder="ยืนยันรหัสผ่าน"
                            onChange={handleChange}
                            isValid={touched.password && !errors.password}
                            isInvalid={!!errors.password}
                            className="form-st-1 mb-4"
                          />
                          <Button
                            type="button"
                            className="btn btn-change-type-password"
                            onClick={() =>
                              inputtype2 === "password"
                                ? setInputtype2("text")
                                : setInputtype2("password")
                            }
                          ></Button>
                        </div>
                      ) : (
                        <div></div>
                      )} */}

                      <div className="d-flex align-items-center mb-4">
                        <div className="me-auto">
                          <Form.Check
                            name="remember"
                            type="checkbox"
                            id="remember"
                            label="จำรหัสผ่าน"
                            className="form-check-st-1"
                            checked={values.remember}
                            onChange={handleChange}
                          />
                        </div>
                        <div>
                          <a href="/forgot-password" className="alink-st-1">
                            ลืมรหัสผ่าน ?
                          </a>
                        </div>
                      </div>
                      <Button type="submit" className="btn-login w-100">
                        เข้าสู่ระบบ
                      </Button>
                    </Form>
                  )}
                </Formik>
                <div className="py-4">
                  <div className="text-center fs-14 fw-5 mb-4">
                    เข้าสู่ระบบด้วยช่องทางอื่น
                  </div>
                  {userinfo ? (
                    <Button
                      type="button"
                      className="btn-login-google login mb-5 w-100"
                    >
                      <div className="picture">
                        <Image src={userinfo.picture} />
                      </div>
                      <div>
                        <div className="name mb-1">{userinfo.name}</div>
                        <div className="email">{userinfo.email}</div>
                      </div>
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      className="btn-login-google mb-5 w-100"
                      onClick={() => login()}
                    >
                      Sign in as Google
                    </Button>
                  )}

                  <div className="text-center">
                    <span className="tc-1 fw-5">ยังไม่มีบัญชี </span>
                    <Link to="/register" className="tc-2 fw-5">
                      สมัครใช้งาน Allder Cafe
                    </Link>
                  </div>
                </div>
              </Card.Body>
            </Card>
            <div className="text-center fs-20 tc-3 d-flex align-items-end justify-content-center">
              <span className="me-3 ">Power By</span>
              <div className="pb-05">
                <Image src="/images/logo_allder.png" width={98} />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
