import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  prefixNames: null,
  shopProductCategories: null,
  restaurantTypes: null,
  entityTypes: null,
  provinces: null,
  districts: null,
  subDistricts: null,
  currencies: null,
  vatCalculations: null,
  codeSettingDays: [],
  codeSettingMonths: [],
  codeSettingYears: [],
  counterSettingMaster: [],
  countUnitTypes: [],
};

const masterSlice = createSlice({
  name: "master",
  initialState,
  reducers: {
    setPrefixNames: (state, action) => {
      state.prefixNames = action.payload;
    },
    setShopProductCategories: (state, action) => {
      state.shopProductCategories = action.payload;
    },
    setRestaurantTypes: (state, action) => {
      state.restaurantTypes = action.payload;
    },
    setEntityTypes: (state, action) => {
      state.entityTypes = action.payload;
    },
    setProvinces: (state, action) => {
      state.provinces = action.payload;
    },
    setDistricts: (state, action) => {
      state.districts = action.payload;
    },
    setSubDistricts: (state, action) => {
      state.subDistricts = action.payload;
    },
    setCurrencies: (state, action) => {
      state.currencies = action.payload;
    },
    setVatCalculations: (state, action) => {
      state.vatCalculations = action.payload;
    },
    setCodeSettingDays: (state, action) => {
      state.codeSettingDays = action.payload;
    },

    setCodeSettingMonths: (state, action) => {
      state.codeSettingMonths = action.payload;
    },
    setCodeSettingYears: (state, action) => {
      state.codeSettingYears = action.payload;
    },
    setCounterSettingMaster: (state, action) => {
      state.counterSettingMaster = action.payload;
    },
    setCountUnitTypes: (state, action) => {
      state.countUnitTypes = action.payload;
    },
    resetState: () => initialState,
  },
});

export const {
  setPrefixNames,
  setShopProductCategories,
  setRestaurantTypes,
  setEntityTypes,
  setProvinces,
  setDistricts,
  setSubDistricts,
  setCurrencies,
  setVatCalculations,
  setCodeSettingDays,
  setCodeSettingMonths,
  setCodeSettingYears,
  setCounterSettingMaster,
  setCountUnitTypes,
  resetState,
} = masterSlice.actions;

export default masterSlice.reducer;
