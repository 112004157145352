import React, { useMemo, useCallback } from "react";
import { DataGrid } from "@mui/x-data-grid";

export const AllderTable = React.memo(function AllderTable({
  data,
  loading,
  columns,
  totalRows,
  paginationModel,
  setPaginationModel,
  handleSort,
  pageSizeOptions = [5, 20, 30],
  customStyles = {},
  rowSelectionEnabled = true,
  ...props
}) {
  const styles = useMemo(
    () => ({
      fontFamily: "Sarabun",
      fontSize: "17px",
      ".MuiDataGrid-columnHeaderTitle": {
        fontFamily: "Sarabun",
        fontSize: "17px",
      },
      ".MuiDataGrid-cell": {
        display: "flex",
        alignItems: "center",
        paddingTop: "5px",
        paddingBottom: "5px",
      },
      ...customStyles,
    }),
    [customStyles]
  );

  const paginationProps = useMemo(
    () => ({
      paginationModel,
      onPaginationModelChange: setPaginationModel,
      pageSizeOptions,
      paginationMode: "server",
      rowCount: totalRows,
    }),
    [paginationModel, setPaginationModel, pageSizeOptions, totalRows]
  );

  const slotProps = useMemo(
    () => ({
      pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
    }),
    []
  );

  const getRowHeight = useCallback(() => "auto", []);

  const getRowId = useCallback((row) => row.id, []);

  return (
    <DataGrid
      loading={loading}
      getRowId={getRowId}
      getRowHeight={getRowHeight}
      rows={data}
      columns={columns}
      {...paginationProps}
      sortingMode="server"
      onSortModelChange={handleSort}
      checkboxSelection={rowSelectionEnabled}
      disableRowSelectionOnClick={rowSelectionEnabled}
      slotProps={slotProps}
      sx={styles}
      {...props}
    />
  );
});

AllderTable.displayName = "AllderTable";

export default AllderTable;
