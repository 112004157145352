import { setData } from "../slices/settingGeneral.slice";
import { ENPOINT, STATUS } from "../../constants/server/api";
import { fetchApiData } from "../../utils/apiUtils";

export const getProductCountUnitById = (id) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENPOINT.PRODUCT_COUNT_UNIT}/${id}`,
    dispatch,
  });

  if (data) {
    return data;
  }
};

export const createProductCountUnit = (requestData) => async (dispatch) => {
  const result = await fetchApiData({
    method: "post",
    endpoint: ENPOINT.PRODUCT_COUNT_UNIT,
    data: requestData,
    dispatch,
  });

  if (result) {
    return result;
  }
};

export const updateProductCountUnit = (id, requestData) => async (dispatch) => {
  const result = await fetchApiData({
    method: "put",
    endpoint: `${ENPOINT.PRODUCT_COUNT_UNIT}/${id}`,
    data: requestData,
    dispatch,
  });

  if (result) {
    return STATUS.OK;
  }
};

export const deleteProductCountUnit = (id) => async (dispatch) => {
  const result = await fetchApiData({
    method: "delete",
    endpoint: `${ENPOINT.PRODUCT_COUNT_UNIT}/${id}`,
    dispatch,
  });

  if (result) {
    return STATUS.OK;
  }
};

export const getProductCountUnits = (params) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENPOINT.PRODUCT_COUNT_UNIT}/pagination/list`,
    params: {
      business_slug: params.business_slug,
      page: params.page,
      perPage: params.per_page,
      s: params.s,
      sortBy: params.sortBy,
      sortOrder: params.sortOrder,
      business_id: params.business_id,
    },
    dispatch,
  });

  if (data) {
    dispatch(setData(data.data));
    return data;
  }
};

export const exportProductCountToExcel =
  (businessSlug, searchTerm) => async (dispatch) => {
    const data = await fetchApiData({
      method: "post",
      endpoint: `${ENPOINT.PRODUCT_COUNT_UNIT}/excel`,
      params: {
        business_slug: businessSlug,
        s: searchTerm || "",
      },
      responseType: "blob",
      dispatch,
    });

    if (data) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "รายการหน่วยนับสินค้า.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    }
  };

export const getProductSaleUnitById = (id) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENPOINT.PRODUCT_SALE_UNIT}/${id}`,
    dispatch,
  });

  if (data) {
    return data;
  }
};

export const createProductSaleUnit = (requestData) => async (dispatch) => {
  const result = await fetchApiData({
    method: "post",
    endpoint: ENPOINT.PRODUCT_SALE_UNIT,
    data: requestData,
    dispatch,
  });

  if (result) {
    return result;
  }
};

export const updateProductSaleUnit = (id, requestData) => async (dispatch) => {
  const result = await fetchApiData({
    method: "put",
    endpoint: `${ENPOINT.PRODUCT_SALE_UNIT}/${id}`,
    data: requestData,
    dispatch,
  });

  if (result) {
    return STATUS.OK;
  }
};

export const deleteProductSaleUnit = (id) => async (dispatch) => {
  const result = await fetchApiData({
    method: "delete",
    endpoint: `${ENPOINT.PRODUCT_SALE_UNIT}/${id}`,
    dispatch,
  });

  if (result) {
    return STATUS.OK;
  }
};

export const getProductSaleUnits = (params) => async (dispatch) => {
  const data = await fetchApiData({
    method: "get",
    endpoint: `${ENPOINT.PRODUCT_SALE_UNIT}/pagination/list`,
    params: {
      business_slug: params.business_slug,
      page: params.page,
      perPage: params.per_page,
      s: params.s,
      sortBy: params.sortBy,
      sortOrder: params.sortOrder,
      business_id: params.business_id,
    },
    dispatch,
  });

  if (data) {
    dispatch(setData(data.data));
    return data;
  }
};

export const exportProductSaleToExcel =
  (businessSlug, searchTerm) => async (dispatch) => {
    const data = await fetchApiData({
      method: "post",
      endpoint: `${ENPOINT.PRODUCT_SALE_UNIT}/excel`,
      params: {
        business_slug: businessSlug,
        s: searchTerm || "",
      },
      responseType: "blob",
      dispatch,
    });

    if (data) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "รายการหน่วยขายสินค้า.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    }
  };
