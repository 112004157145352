import { env } from "../../env";

export const domain = env.REACT_APP_API_URL;

export const STATUS = {
  YES: "YES",
  NO: "NO",
  OK: "OK",
  TOKEN: "TOKEN",
};

export const ENPOINT = {
  USER_BUSINESS_GROUP: "bo/user-business-group",
  PRODUCT_COUNT_UNIT: "bo/product-count-unit",
  BUSINESS: "bo/business",
  CODE_SETTING: "bo/code-setting",
  PRODUCT_SALE_UNIT: "/bo/product-sale-unit",
  MASTER: {
    PREFIX_NAME: "prefix-name",
    SHOP_PRODUCT: "shop-product-category",
    RESTAURANT: "restaurant-type",
    ENTITY: "entity-type",
    PROVINCE: "province",
    DISTRICT: "district",
    SUB_DISTRICT: "sub-district",
    CURRENCY: "currency",
    VAT: "vat-calculation",
    CODE_SETTING_DAY: "code-setting-day",
    CODE_SETTING_MONTH: "code-setting-month",
    CODE_SETTING_YEAR: "code-setting-year",
    COUNTER_SETTING: "counter-setting-master",
    COUNT_UNIT_TYPE: "count-unit-type",
  },
};
