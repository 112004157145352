import React from "react";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";
import { ModalAllder } from "layouts/ModalAllder";

export function ConfirmDeleteModal({
  modal,
  setModal,
  onConfirm,
  onHide,
  onSuccess,
  unitFormData = {},
}) {
  const handleDelete = () => {
    if (onConfirm) {
      onConfirm();
    }
    if (onHide) {
      onHide();
    }
    if (onSuccess) {
      onSuccess();
    }
  };

  return (
    <ModalAllder
      modal={modal}
      setModal={setModal}
      htitle={"ยืนยันการลบ"}
      hbtn={false}
    >
      <div className="py-4">
        {unitFormData?.productQty > 0 ? (
          <div>
            <div>
              <div className="text-center fs-22 tc-7 mb-2">
                ขออภัยไม่สามารถลบได้
              </div>
              <div className="text-center fs-22 tc-7">
                เนื่องจากมีสินค้าอยู่ในหน่วยนับ
              </div>
            </div>
            <div className="text-center pt-4 mt-3 btn-group-control">
              <Button
                type="button"
                className="btn-st-3 mx-3"
                style={{ width: "100px" }}
                onClick={onHide}
              >
                ตกลง
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <div>
              <div className="text-center fs-22 tc-7 mb-2">คุณต้องการลบ</div>
              <div className="text-center fs-22 tc-7">
                "{unitFormData?.name}" ใช่หรือไม่
              </div>
            </div>

            <div className="text-center pt-4 mt-3 btn-group-control">
              <Button
                type="button"
                className="btn-st-3 mx-3"
                style={{ width: "100px" }}
                onClick={handleDelete}
              >
                ใช่
              </Button>
              <Button
                type="button"
                className="btn-st-6 mx-3"
                style={{ width: "100px" }}
                onClick={onHide}
              >
                ไม่ใช่
              </Button>
            </div>
          </div>
        )}
      </div>
    </ModalAllder>
  );
}
