import React from "react";
import { Link } from "react-router-dom";

export function NotFound() {
  const token = localStorage.getItem("accessToken");

  return (
    <div className="d-flex flex-column align-items-center justify-content-center min-vh-100 bg-light">
      <div className="text-center py-5 px-28 bg-white rounded shadow">
        <h1 className="display-1 fw-bold text-primary mb-4">404</h1>      
        <h2 className="h3 mb-4">Page Not Found</h2>
        <p className="text-muted mb-4">ขออภัย ไม่พบหน้าที่คุณต้องการ</p>
        <Link
          to={token ? "business" : "/login"}
          className="btn btn-primary px-4 py-2"
        >
          {token ? "กลับสู่หน้าหลัก" : "กลับสู่หน้าเข้าสู่ระบบ"}
        </Link>
      </div>
    </div>
  );
}
