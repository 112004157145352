import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { ModalAllder } from "layouts/ModalAllder";

export function SuccessDeleteModal({ modal, setModal, msg, ...props }) {
  return (
    <ModalAllder modal={modal} setModal={setModal} htitle={""} hbtn={false}>
      <div className="py-4">
        <div className="text-center mb-4">
          <svg
            className="mx-auto"
            width="84"
            height="83"
            viewBox="0 0 84 83"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="42" cy="41.5" r="41.5" fill="#2DC76D" />
            <path
              d="M56.0123 30.9902L36.7444 50.2581L27.9863 41.5"
              stroke="white"
              strokeWidth="5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="text-center fs-22 tc-7">{msg}</div>
      </div>
    </ModalAllder>
  );
}
