import { useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";

export const useGlobalTable = ({
  fetchData,
  defaultSort = "created_at",
  defaultOrder = "desc",
  defaultPageSize = 20,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: defaultPageSize,
  });
  const [filterParams, setFilterParams] = useState({
    s: "",
    sortBy: defaultSort,
    sortOrder: defaultOrder,
  });

  const fetchTableData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetchData({
        page: paginationModel.page + 1,
        per_page: paginationModel.pageSize,
        s: filterParams.s,
        sortBy: filterParams.sortBy,
        sortOrder: filterParams.sortOrder,
      });

      if (response?.data) {
        setData(response.data);
        setTotalRows(response.meta.total);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [fetchData, paginationModel, filterParams]);

  const handleSort = (newSortModel) => {
    if (newSortModel.length > 0) {
      setFilterParams((prev) => ({
        ...prev,
        sortBy: newSortModel[0].field,
        sortOrder: newSortModel[0].sort,
      }));
    } else {
      setFilterParams((prev) => ({
        ...prev,
        sortBy: defaultSort,
        sortOrder: defaultOrder,
      }));
    }
  };

  const debouncedSearch = debounce((value) => {
    setFilterParams((prev) => ({ ...prev, s: value }));
  }, 300);

  useEffect(() => {
    fetchTableData();
  }, [fetchTableData]);

  return {
    data,
    loading,
    totalRows,
    paginationModel,
    setPaginationModel,
    filterParams,
    setFilterParams,
    handleSort,
    debouncedSearch,
    refreshData: fetchTableData,
  };
};
